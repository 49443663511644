@import "./variables";
@import "./typography";
@import "./forms";
@import "./calendar";
@import "./admin-layout";
@import "./admin-table";
@import "./utility";
@import "./admin-one-common";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
*::-webkit-scrollbar{
  width: 0.9rem;
  height: 0.8rem;
}
*::-webkit-scrollbar-thumb {
  border-radius: 3rem;
  border: 2px solid var(--gray-text2);
  background-color: var(--gray-text5);
}
body {
  background-color: #f7f7f9;
}
.cdk-overlay-container{
  z-index: 100000000 !important;
}

