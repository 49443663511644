.reports-container {
  margin: 1rem 0;
  .reports {
    background-color: #f7f7f9;
    padding: 1rem 3rem;
    .title-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .download-loading {
        height: 3rem;
        width: 3rem;
        margin-right: 1rem;
      }
    }
  }
  .admin-button2 {
    display: flex;
    margin: 0 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    color: #ffffff;
    background: linear-gradient(180deg, #3679fb 0%, #285bbc 100%);
    box-shadow: inset 0px -3px 5px #2656b3, inset 0px 4px 4px #387dff;
    border-radius: 6px;
    cursor: pointer;
    &:disabled {
      background: gray;
      box-shadow: inset 0px -3px 5px rgb(140, 140, 140),
        inset 0px 4px 4px rgb(116, 116, 116);
    }
    &:hover{
      background: var(--primary);
      box-shadow: none;
    }
    .button-icon {
      height: 1.3rem;
      margin-right: 1rem;
    }
    label {
      font-weight: 400;
      font-size: 1.2rem;
      cursor: pointer;
    }
  }
}

.master-wrapper {
  padding: 2rem 0;
  min-height: 80vh;
  .top-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    .heading {
      font-size: 1.8rem;
      font-weight: 500;
    }
    .controllers {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      @media (max-width: 600px) {
        width: 100%;
      }
      .coltroller-bottons {
        display: flex;
        gap: 1rem;
      }
    }
  }
}

.details-section {
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  border-radius: 1.5rem 1.5rem 0 0;
  border-bottom: 1px solid #dbdbdb;
  h3 {
    margin-top: 0.5rem;
  }
  h6 {
    color: var(--gray-text2);
  }
  .data {
    display: flex;
    .data-item {
      margin-right: 1rem;
      max-width: 10rem;
      text-align: end;
    }
  }
}
@media (max-width: 600px) {
  .details-section {
    flex-direction: column;
    .data {
      flex-wrap: wrap;
      .data-item {
        max-width: 8rem;
        margin: 1rem 0;
        margin-right: 0.5rem;
        text-align: start;
      }
    }
  }
}
.admin-table {
  padding: 0 1rem 0rem 0;
  background-color: #ffffff;
  display: block;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 180px);
  border-radius: 0 0 1.5rem 1.5rem;
  &.individual-table {
    border-radius: 1.5rem;
  }

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow: auto;
  table {
    width: 100% !important;
    max-width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
    thead {
      tr {
        height: 50px;
        th {
          min-width: 90px;
          text-align: left;
          color: black;
          font-weight: 500;
          top: -1px;
          border-bottom: 1px solid #dbdbdb;
          padding-right: 5px;
          padding-left: 8px;
          text-transform: uppercase;
          line-height: 1.5rem;
          position: sticky;
          background: var(--white);
        }
      }
    }
    tbody {
      tr {
        height: 5rem;
        border-bottom: 1px solid #dbdbdb;
        &:last-child {
          border-bottom: 0px solid #dbdbdb;
        }
        td {
          min-width: 133px;
          padding-right: 10px;
          padding-left: 8px;
          line-height: 1.5rem;
        }
        .change-status {
          min-width: 2rem;
          cursor: pointer;
          i {
            font-size: 1.6rem;
            color: rgba(132, 135, 139, 1);
          }
        }
      }
    }
  }
}
.admin-table::-webkit-scrollbar{
  width: 0.9rem;
}
.mat-menu-content:not(:empty) {
  padding-top: 0px;
  padding-bottom: 0px;
}

::ng-deep {
  .mat-menu-panel {
    width: 25rem !important;
    .change-status-dialog {
      padding: 1rem;
      h5 {
        margin: 1rem 0;
      }
      .filter-item {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        label {
          font-size: 1.4rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
        select {
          padding: 0 0.8rem;
          height: 4rem;
          border-radius: 6px;
          font-size: 1.3rem;
          border: 1px solid #e7e9eb;
          color: #8e8e8e;
        }
      }
      .actions {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

// table pagination styles
.ngx-pagination {
  padding: 0;
  margin-bottom: 20px;
  li {
    &:not(.pagination-previous, .pagination-next) {
      width: 23px;
      height: 23px;
      margin: 0px 5px;
    }
    padding: 0 !important;
    color: #141415;
    text-align: center;
    border-radius: 5px !important;
    font-size: 1.4rem;
    &.current {
      background-color: #141415;
      color: #fff;
    }

    a {
      &:hover,
      &:focus {
        outline: none;
        border-radius: 5px;
      }
      &:after,
      &:before {
        display: none !important;
      }
    }

    &:after,
    &:before {
      content: "" !important;
    }
  }
}

.custom-pagination .page-number {
  display: inline-block;
  margin: 4px 4px;
  border-radius: 10px;
  text-align: center;
  vertical-align: middle;
}

.page-number.current {
  background: linear-gradient(180deg, #3679fb 0%, #285bbc 100%);
  border-radius: 6px;
  color: #fff;
}

.page-number span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 28px;
  height: 28px;
  padding: 5px;
  font-size: 1.4rem;
  cursor: pointer;
}

.pagination-previous,
.pagination-next {
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  display: inline-flex;
  font-weight: normal;
  cursor: pointer;
  vertical-align: middle;
  font-size: 20px;
  span {
    height: 27px;
    width: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-pagination {
  margin-top: 1rem;
  .itemsperPage {
    label {
      color: #141415;
    }
    .mat-form-field-infix {
      max-width: 100px;
    }
  }
}

.bold-text {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.1rem;
}

.light-text {
  color: #8e8e8e;
}
.normal-text {
  font-size: 1.2rem;
  font-weight: 300;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  z-index: 999;
  &.show {
    display: block;
  }
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  padding: 1rem;
  right: 0;
  background-color: #ffffff;
  min-width: 160px;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  &.show {
    display: block;
  }
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    padding: 1rem;
    &:hover {
      background-color: #f1f3f5;
      border-radius: 6px;
    }
  }
}

@media (max-width: 600px) {
  .dropdown-content {
    left: 0;
    right: initial;
  }
}

.filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 1rem;
  .filter-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 1rem;
    label {
      color: #233f8c;
      font-size: 1rem;
      font-weight: 600;
    }
    .content {
      display: flex;
      align-items: center;
      padding: 0.4rem;
      background: rgba(0, 122, 255, 0.1);
      border: 1px solid #387dff;
      border-radius: 5px;
      color: #387dff;
    }
  }
  .clear-all {
    font-size: 1rem;
    color: #8e8e8e;
  }
}
