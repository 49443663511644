.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.c-pointer {
  cursor: pointer;
}
.empty-table {
  text-align: center;
  margin-bottom: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-1 {
  margin-left: 0.5rem;
}
