/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/styles/index";

html,
body {
  font-size: 10px;
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
  html,
  body {
    font-size: 12px;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  html,
  body {
    font-size: 9px;
  }
}
