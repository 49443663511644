.cal-month-view .cal-day-cell.cal-today {
  background-color: #fff !important;
}

.calendar-controll {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h5 {
    min-width: 125px;
    font-weight: 300;
    @media (max-width: 670px) {
      min-width: 100px;
    }
  }
  .arrow {
    margin: 0px 10px;
    font-size: 15px;
    color: #387dff;
    cursor: pointer;
  }
}

.calender-box {
  padding: 5px;
  display: flex;
  flex-direction: column;
  .cal-cell-top {
    min-height: auto;
    flex: 1;
    .cal-day-number {
      margin-top: 5px;
      float: left;
    }
  }
  .cal-cell-below {
    .calItems {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 80px;
      @media (max-width: 670px) {
        min-height: auto;
      }
      .status {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 5px;
        @media (max-width: 670px) {
          font-size: 8px;
        }
        &.green {
          color: #109072;
        }
        &.red {
          color: #c72626;
        }
      }
      .content-box {
        display: flex;
        flex-direction: column;
        .button-style {
          margin-bottom: 5px;
          padding: 4px 5px;
          font-weight: 300;
          background: linear-gradient(180deg, #3679fb 0%, #285bbc 100%);
         box-shadow: inset 0px -3px 5px #2656b3, inset 0px 4px 4px #387dff;
          color: white;
          border-radius: 5px;
          width: fit-content;
          font-size: 11px;
          cursor: pointer;
          &:hover{
            background: var(--primary);
            box-shadow: none;
          }
          @media (max-width: 670px) {
            font-size: 8px;
          }
          @media (max-width: 530px) {
            font-size: 6px;
            padding: 2px 3px;
            width: 100%;
          }
        }
        .request-pending {
          text-align: center;
          margin-bottom: 5px;
          padding: 4px 5px;
          font-size: 11px;
          font-weight: 500;
          color: #c72626;
          outline: #c72626;
          border: 1px solid #c72626;
          border-radius: 50px;
          width: fit-content;
          @media (max-width: 670px) {
            font-size: 8px;
          }
          @media (max-width: 530px) {
            font-size: 5px;
            padding: 2px 3px;
            border-radius: 5px;
          }
        }
        .request-approved {
          text-align: center;
          margin-bottom: 5px;
          padding: 4px 5px;
          font-size: 11px;
          font-weight: 500;
          color: #109172;
          outline: #109172;
          border: 1px solid #109172;
          border-radius: 50px;
          width: fit-content;
          @media (max-width: 670px) {
            font-size: 8px;
          }
          @media (max-width: 530px) {
            font-size: 5px;
            padding: 2px 3px;
            border-radius: 5px;
          }
        }
        .request-rejected {
          text-align: center;
          margin-bottom: 5px;
          padding: 4px 5px;
          font-size: 11px;
          font-weight: 500;
          color: #8e8e8e;
          outline: #8e8e8e;
          border: 1px solid #8e8e8e;
          border-radius: 50px;
          width: fit-content;
          @media (max-width: 670px) {
            font-size: 8px;
          }
          @media (max-width: 530px) {
            font-size: 5px;
            padding: 2px 3px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

::ng-deep .cal-day-cell .bg-pink {
  background-color: hotpink !important;
}

::ng-deep .cal-day-cell.cal-today {
  background-color: transparent !important;
}

.cal-month-view .cal-header .cal-cell {
  padding-left: 1rem;
  text-align: left;
  border: 0.5px solid #e1e1e1;
  font-weight: 400;
}
