:root{
    --primary: #387DFF;
    --primary-dark: #233F8C;
    --primary-light: rgba(0, 122, 255, 0.1);
    --primary-light2: rgba(0, 122, 255, 0.05);
    --primary-background: rgba(40, 91, 188, 1);
    --success: #109072;
    --error: #C72626;
    --white: #ffff;
    --black: #000;
    --gray: rgba(219, 219, 219, 1);
    --gray-border1: rgba(219, 219, 219, 1);
    --gray-border2: rgba(233, 233, 233, 1);
    --gray-text1: rgba(0, 0, 0, 0.5);
    --gray-text2: rgba(142, 142, 142, 1);
    --gray-text3: rgba(35, 63, 140, 0.8);
    --gray-text4: rgba(73, 73, 73, 1);
    --gray-text5: rgba(173, 173, 173, 1);
    --gray-background: rgba(0, 0, 0, 0.06);
    --gray-background2: rgba(247, 247, 249, 1);
    --gray-background3: rgba(241, 243, 245, 1);
    --blue-background1: rgba(242, 248, 255, 1);
    --background: rgba(243, 243, 243, 1);
}