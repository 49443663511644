button {
  border: none;
  outline: none;
}
.btn-1 {
  padding: 0.7rem 1rem;
  margin-left: 2rem;
  color: var(--primary) !important;
  background-color: var(--white);
  border-radius: 0.3rem;
  border: 0.1rem solid var(--primary);
  cursor: pointer;
  &:hover{
    background-color: var(--primary);
    color: var(--white) !important;
  }
}
.btn-2 {
  padding: 0.8rem 1.8rem;
  background: linear-gradient(180deg, #3679fb 0%, #285bbc 100%);
  box-shadow: inset 0px -3px 5px #2656b3, inset 0px 4px 4px #387dff;
  border-radius: 6px;
  cursor: pointer;
  color: var(--white) !important;
  &:hover{
    background: var(--primary);
    box-shadow: none;
  }
  &:disabled{
    background: gray !important;
    box-shadow: none !important;
  }
}
.btn-3 {
  padding: 1rem 2rem;
  background-color: var(--white);
  cursor: pointer;
  color: var(--primary);
}
.button-blue {
  min-width: 6rem;
  padding: 1rem;
  background: linear-gradient(180deg, #3679fb 0%, #285bbc 100%);
  border-radius: 6px;
  color: #fff;
  &:disabled{
    background: gray !important;
    box-shadow: none !important;
  }
  &:hover{
    background: var(--primary);
    box-shadow: none;
  }
}
.button-white {
  min-width: 6rem;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dcdddf;
  border-radius: 6px;
  color: #8e8e8e;
  &:hover{
      background: rgba(0, 122, 255, 0.1) !important;
  }
}
.gray-hover{
  &:hover{
    background: rgba(0, 122, 255, 0.1) !important;
    img{
      filter: brightness(0) saturate(100%) invert(44%) sepia(22%) saturate(2174%) hue-rotate(186deg) brightness(102%) contrast(111%) !important;
    }
  }
}
.red-hover{
  &:hover{
    color: red !important;
    img{
      filter: invert(11%) sepia(71%) saturate(7487%) hue-rotate(359deg) brightness(111%) contrast(108%) !important;
    }
  }
}
.link-hover{
  &:hover{
    color: var(--primary-dark) !important;
  }
}
input {
  border: 0.1rem solid rgba(217, 217, 217, 1);
  border-radius: 0.3rem;
  outline: none;
}
input[type="text"] {
  padding: 0 1rem;
  height: 4rem;
}
.text-overflow-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.active {
  white-space: initial !important;
  overflow: visible !important;
  text-overflow: initial !important;
  overflow-wrap: break-word;
}
.svg-blue{
  filter: brightness(0) saturate(100%) invert(44%) sepia(22%) saturate(2174%) hue-rotate(186deg) brightness(102%) contrast(111%);
}
.svg-gray{
  filter: brightness(0) saturate(100%) invert(57%) sepia(77%) saturate(9%) hue-rotate(340deg) brightness(85%) contrast(86%);
}
.svg-black{
  filter: brightness(0) saturate(100%) invert(0%) sepia(93%) saturate(28%) hue-rotate(104deg) brightness(96%) contrast(106%);
}
.svg-white{
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(109deg) brightness(104%) contrast(101%);
}
.icon{
  height: 1.6rem !important;
  width: 1.6rem !important;
}

.admin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: #ffffff;
  border: 1px solid #dddfe0;
  border-radius: 6px;
  .button-icon {
    height: 1.3rem;
    margin-right: 1rem;
  }
}

.input-wrapper {
  position: relative;
  .input-icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }
  .input-box {
    height: 2.8rem;
    min-width: 20rem;
    padding-left: 3rem;
    border-radius: 6px;
  }
}
@media (max-width: 600px) {
  .input-wrapper {
    margin-top: 1rem;
    width: 100%;
    .input-box {
      width: 100%;
    }
  }
}


.sticky-col{
  position: sticky;
  left: -1px;
  background-color: var(--white);
}
.second-col{
  left: 10rem;
}
.col-sm{
  min-width: 0 !important;
  width: 90px !important;
}
