.d-lg-none {
  display: block !important;
  margin-right: 2rem;
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
}
