html {
  font-size: 10px;
}

@media (max-width: 768px) {
  html {
    font-size: 9px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 8px;
  }
}
@media (min-width: 1400px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 1800px) {
  html {
    font-size: 16px;
  }
}
h1 {
  font-size: 3.4rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.8rem;
}
h4 {
  font-size: 1.6rem;
}
h5 {
  font-size: 1.4rem;
}
h6 {
  font-size: 1.2rem;
}
.w-100 {
  font-weight: 100;
}
.w-200 {
  font-weight: 200;
}
.w-300 {
  font-weight: 300;
}
.w-400 {
  font-weight: 400;
}
.w-500 {
  font-weight: 500;
}
.w-600 {
  font-weight: 600;
}
.w-900 {
  font-weight: 900;
}

.text-danger {
  color: var(--error);
}
