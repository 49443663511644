.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
  .admin-button2 {
    display: flex;
    margin: 0 0.5rem;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    color: #ffffff;
    background: linear-gradient(180deg, #3679fb 0%, #285bbc 100%);
    box-shadow: inset 0px -3px 5px #2656b3, inset 0px 4px 4px #387dff;
    border-radius: 6px;
    cursor: pointer;
    .button-icon {
      height: 1.3rem;
      margin-right: 1rem;
    }
    label {
      font-weight: 400;
      font-size: 1.2rem;
      cursor: pointer;
    }
    &:hover{
      background: var(--primary);
      box-shadow: none;
    }
  }
  .v-seperator {
    height: 2rem;
    width: 0.1rem;
    background-color: rgba(215, 216, 217, 1);
    margin: 0 0.5rem;
  }
}
@media (max-width: 600px) {
  .actions {
    justify-content: flex-start;
    flex-wrap: wrap;
    .admin-button2 {
      margin-top: 1rem;
    }
    .v-seperator {
      display: none;
    }
  }
}
